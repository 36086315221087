.hoverImage_container {
    display: inline-block;
    position: relative;

    .hoverImage_imageContainer {
        display: none;
    }

    &:hover {
        .hoverImage_imageContainer {
            position: absolute;
            left: 50%;
            display: flex;
            justify-content: center;
            transform: translateY(-2em);

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 50%) rotate(45deg);
                height: 8px;
                width: 8px;
                background-color: var(--black);
            }

            img {
                position: absolute;
                bottom: 0;
                width: 200px;
                border: 3px solid var(--black);
                background-color: white;
            }
        }
    }
}

.hoverImage_text {
    text-decoration: underline;
}

.hoverImage_imageContainer {

}