.section_scrollContainer {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    width: 100%;
    position: relative;
}

.section_scrollManager {
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.section_contentContainer {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 72px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
}

.section_donutImageContainer {
    margin-right: 36px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
}

.section_textContainer_mounted, .section_textContainer_unmounted {
    text-align: left;
    width: 600px;
    max-width: 100%;
    line-height: 1.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: all;

    h2 {
        margin-bottom: 0;
    }
}

.section_textContainer_mounted {
    opacity: 1;
    transition: 0.3s all 0.1s;
}

.section_textContainer_unmounted {
    opacity: 0;
    transform: translateY(12px);
    transition: 0.4s all 0s;
}

@media only screen and (max-width: 600px) {
    .section_contentContainer {
        flex-direction: column;
        padding: 72px 32px;
        pointer-events: none;

        .section_textContainer_mounted, .section_textContainer_unmounted {
            pointer-events: none;

            a, .email-copy {
                pointer-events: all;
            }
        }
    }

    .section_donutImageContainer {
        width: 50%;
        padding: 12px;
        margin: 0;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        pointer-events: none;
    }

    .section_textContainer_mounted, .section_textContainer_unmounted {
        justify-content: start;
        font-size: 0.55em;
    }

    h2 {
        margin-top: 0;
    }
}