.img_unmounted {
    #SCREEN_FRAME, #KEYBOARD, #TRACKPAD {
        transform: translateY(15%);
        opacity: 0;
        transition: 0.8s;
        transition-delay: 0s;
    }

    #SCREEN {
        opacity: 0;
        transform: translateY(0) translateX(3%);
        transition: 0.8s;
        transition-delay: 0s;
    }

    #MUG {
        transform: translateY(-20%);
        opacity: 0;
        transition: 0.8s;
        transition-delay: 0s;
    }
}

.img_mounted * {
    transition: 0.8s;
}

#SCREEN_FRAME {
    transition-delay: 0s;
}

#KEYBOARD {
    transition-delay: 0.3s;
}

#TRACKPAD {
    transition-delay: 0.6s;
}

#MUG {
    transition-delay: 1s;
}

#SCREEN {
    transition-delay: 1.2s;
}