.img_unmounted {
    #BELT {
        transform: translateY(20%);
        opacity: 0;
        transition: 0.8s;
    }

    #MACHINE_BACK, #MACHINE_FRONT {
        transform: translateY(-20%);
        opacity: 0;
        transition: 0.8s;
    }

}

#NOTES {
    opacity: 0;
}

#PLANTER {
    opacity: 0;
    transition: 0.8s;
}

.img_mounted * {
    #PLANTER {
        animation: planter-move-along-conveyer 3s 1.5s infinite;
        #PLANT {
            animation: appear-plant 3s 1.5s infinite;
        }
    }

    #TRACKS {
        animation: move-conveyer-tracks 3s 1.5s infinite;
    }
}

@keyframes planter-move-along-conveyer {
    0% {
        opacity: 0;
        transform: translate(210px, calc(210px * -0.577 - 24px));
    } 20% {
        opacity: 1;
        transform: translate(210px, calc(210px * -0.577));
    } 30% {
        opacity: 1;
        transform: translate(210px, calc(210px * -0.577));
    } 70% {
        opacity: 1;
        transform: translate(0, 0);
    } 85% {
        opacity: 1;
        transform: translate(0, 0);
    } 100% {
        opacity: 0;
        transform: translate(0, -24px);
    }
}

@keyframes appear-plant {
    0% {
        opacity: 0;
    } 40% {
        opacity: 0;
    } 50% {
        opacity: 1;
    } 100% {
        opacity: 1;
    }
}


@keyframes move-conveyer-tracks {
    0% {
        transform: translate(110px, calc(-110px * 0.577));
    } 30% {
        transform: translate(110px, calc(-110px * 0.577));
    } 80% {
        transform: translate(0, 0);
    } 100% {
        transform: translate(0, 0);
    }
}
