.img_unmounted {
    #MSG3, #MSG2, #MSG1 {
        transform: translateX(-15%);
        opacity: 0;
        transition: 0.8s;
    }

    #FRONT {
        transform: translateY(15%);
        opacity: 0;
        transition: 0.8s;
    }
}

.img_mounted * {
    transition: 0.8s;
    #MSG1>g, #MSG2>g, #MSG3>g, #FRONT>g {
        &:hover {
            transform: translateY(-36px);
            transition-delay: 0;
            transition: 0.2s;
        }
    }
}

#FRONT {
    transition-delay: 0s;
}

#MSG1 {
    transition-delay: 0.5s;
}

#MSG2 {
    transition-delay: 0.8s;
}

#MSG3 {
    transition-delay: 1.2s;
}