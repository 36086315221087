#icon-links {
  display: flex;
  height: 1.8em;
  margin-top: 3em;

  a {
    display: block;
    height: 100%;
    position: relative;
    margin-right: 12px;
  }
}

.email-copy {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: var(--dark-blue);

  &:hover {
    &:after {
      content: "Copy to clipboard";
      display: block;
      position: absolute;
      background: #333333;
      padding: 0.1em 1em;
      font-size: 0.8em;
      left: 50%;
      transform: translateX(-50%) translateY(6px);
      white-space: nowrap;
      color: white;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      background: #333333;
      height: 12px;
      width: 12px;
      left: 50%;
      bottom: -6PX;
      transform: translate(-50%, 6px) rotate(45deg)
    }
  }
  

  &.copied {
    &:hover {
      &:after {
        content: "Copied!";
      }
    }
  }
}