.img_unmounted {
    #BASE {
        transform: translateY(15%);
        transition: 0.8s;
        #BASE_FILL, #BASE_STROKE {
            opacity: 0;
        }
    }

    #BLOCK1, #BLOCK2, #BLOCK3, #BLOCK4 {
        opacity: 0;
        transition: 0.8s;
        transform: translateY(-20%);
    }

    #BLOCK1_FILL, #BLOCK2_FILL, #BLOCK3_FILL, #BLOCK4_FILL {
        opacity: 0;
        transition-delay: 0s;
        
    }
}

.img_mounted * {
    transition: 0.8s;
    #BLOCK1>g, #BLOCK2>g, #BLOCK3>g, #BLOCK4>g {
        &:hover {
            transform: translateY(-12px);
            transition-delay: 0;
            transition: 0.2s;
        }
    }

}

#BASE {
    transition-delay: 0;
    #BASE_FILL {
        opacity: 1;
        transition-delay: 0.5s;
    }

    #BASE_STROKE {
        opacity: 1;
        transition-delay: 0;
    }
}

#BLOCK1 {
    transition-delay: 0.9s;

    #BLOCK1_FILL {
        transition-delay: 2s;
    }
}
#BLOCK2 {
    transition-delay: 1.1s;

    #BLOCK2_FILL {
        transition-delay: 2s;
    }
}
#BLOCK3 {
    transition-delay: 1.2s;
    #BLOCK3_FILL {
        transition-delay: 2s;
    }
}
#BLOCK4 {
    transition-delay: 1s;
    #BLOCK4_FILL {
        transition-delay: 2s;
    }
}