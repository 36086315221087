.img_mounted, .img_unmounted {
    transition: 0.8s;
    position: relative;
    display: flex;
    justify-content: center;
    pointer-events: all;
}

.img_unmounted {
    transform: translateY(10%);
    transition: 0.5s;
}

.img_mounted > *, .img_unmounted > * {
    position: relative;
    width: 80%;
}

svg {
    overflow: visible;
}

.img_mounted {
    animation: bobble 0.8s 2s ease-in-out infinite alternate;
}

@keyframes bobble {
    from {
        transform: translateY(0);
    } to {
        transform: translateY(6px);
    }
}

@media only screen and (max-width: 600px) {
    .img_mounted, .img_unmounted {
        pointer-events: none;
    }
}