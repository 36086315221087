.donut_container {
    width: 100%;
    -webkit-transform: perspective(550px) rotateX(53deg);
    transform: perspective(550px) rotateX(53deg);
    transition: 0.2s;
    animation-fill-mode: forwards;
    margin-top: -50%
}

.donut {
    stroke-dasharray: 1300;
    stroke-dashoffset: 0;
    transition: 0.2s;
}