header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 900px;
    top: 0;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 48px;
    z-index: 2;
    transition: 0.2s;

    #nav_home {
        font-weight: 600;
        cursor: pointer;
        padding: 24px 0;
        margin: 0;
        text-align: left;
    }

    .nav_number_container {
        display: flex;
        flex-grow: 1;
        flex-direction: row-reverse;
        justify-content: flex-start;

        .nav_number {
            cursor: pointer;
            padding: 24px 0.5em;
            height: 100%;
            margin-left: 24px;
            font-style: italic;
        }

        &:hover {
            nav {
                max-width: 100%;
            }
        }
    }

    nav {
        display: flex;
        height: 100%;
        max-width: 0;
        overflow: hidden;
        transition: 1s;

        div {
            cursor: pointer;
            padding: 24px 0.5em;
            height: 100%;
            margin-left: 24px;
            white-space: nowrap;
        }

        .selected {
            font-weight: 700;
        }
    }
}

@media only screen and (max-width: 600px) {
    header {
        padding: 0 12px;

        .nav_number_container {
            nav {
                display: none;
                transform: translateY(0);
                display: flex;
                height: 100%;
                justify-content: space-evenly;
                align-items: center;
        
                div {
                    cursor: pointer;
                    padding: 24px 0.5em;
                    height: 100%;
                    margin: 0;
                }
            }

            &:hover {
                nav {
                    display: none;
                }
            }
        }
    }
}