.img_unmounted {
    #hey, #hey_1, #hey_2, #hey_3 {
        transform: translateY(15%);
        opacity: 0;
        transition: 0.8s;
    }

    #hey {
        #h_fill-4, #e_fill-4, #y_fill-4 {
            opacity: 0;
            transition-delay: 1s
        }
    }
}

.img_mounted * {
    transition: 0.8s;
}

#hey {
    #h_fill-4, #e_fill-4, #y_fill-4 {
        opacity: 1;
        transition-delay: 1.8s
    }
}

#hey_1 {
    opacity: 0.5;
    transition-delay: 0.5s;
}

#hey_2 {
    opacity: 0.3;
    transition-delay: 0.8s;
}

#hey_3 {
    opacity: 0.1;
    transition-delay: 1s;
}

#hey>*, #hey_1>*, #hey_2>*, #hey_3>* {
    &:hover {
        transform: translateY(-32px);
        transition: 0.2s;
    }
}